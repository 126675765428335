import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Collapse,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    styled,
} from '@mui/material';
import { Directions, Add, CloudUpload, UploadFile } from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import '@toast-ui/editor/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import AWS from 'aws-sdk';

const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const parseNumber = (formattedNumber) => {
    return formattedNumber.toString().replace(/,/g, '');
};

export default function GolfInformationModifyView() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const getParams = useParams();
    const [me, setMe] = useState('');

    const [club, setClub] = useState('');
    const [uploadErrorAlert, setUploadErrorAlert] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const editorRefs = useRef([]);

    const clubType = [
        { id: 1, name: '회원제' },
        { id: 2, name: '퍼블릭 18홀 이상(P)' },
        { id: 3, name: '퍼블릭 18홀 미만(P)' },
        { id: 99, name: '기타' },
    ];

    const clubRegionArray = [
        { id: 1, name: '경기북부' },
        { id: 2, name: '경기남부' },
        { id: 3, name: '강원권' },
        { id: 4, name: '충청권' },
        { id: 5, name: '경상권' },
        { id: 6, name: '전라권' },
        { id: 7, name: '제주도' },
        { id: 99, name: '기타' },
    ];

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    
    useEffect(() => {
        if (!localStorage.getItem('auth-token')) {
            navigate('/login');
        }
    });

    useEffect(() => {
        setMe(JSON.parse(localStorage.getItem('me')));
    }, []);

    useEffect(() => {
        console.log('useEffect');
        if (me) {
            if (
                me.club_admin_level !== 10 &&
                me.club_id !== parseInt(getParams.id)
            ) {
                alert('골프 회원 정보가 일치하지 않습니다.');
                navigate('/dc/bookingdiscount');
            }
            getClub(getParams.id);
            getStates();
        }
    }, [getParams.id, me, navigate]);

    useEffect(() => {
        setSuccessAlert(false);
        setErrorAlert(false);
    }, [club]);

    const getClub = async (id) => {
        const params = {
            token: localStorage.getItem('auth-token'),
        };
        const lists = await axios.get(`${apiUrl}/club/admin/info/${id}`, {
            params,
        });
        console.log(lists);
        setClub(lists.data.data[0]);
    };

    const getStates = async () => {
        const params = {
            token: localStorage.getItem('auth-token'),
        };
        const lists = await axios.get(`${apiUrl}/club/admin/states`, {
            params,
        });
        console.log(lists);
        setCities(lists.data.data);
    };
    const getCities = async (id) => {
        const params = {
            token: localStorage.getItem('auth-token'),
            state_id: id,
        };
        const lists = await axios.get(`${apiUrl}/club/admin/cities`, {
            params,
        });
        console.log(lists);
        // setCities(lists)
    };
    console.log('club :', club);
    // {
    // 	"id": 378,
    // 	"club_code": "0301001",
    // 	"club_type": 3,
    // 	"club_region": 1,
    // 	"name": "123(P6)",
    // 	"holes": 6,
    // 	"courses": "OUT",
    // 	"phone": "02-359-0123",
    // 	"region": "경기 고양",
    // 	"address": "경기도 고양시 덕양구 통일로 43-168",
    // 	"webpage": "http://www.123golfclub.co.kr/",
    // 	"weekday_fee": 26000,
    // 	"weekend_fee": 29000,
    // 	"cart_fee": 2000,
    // 	"caddie_fee": 100000,
    // 	"latitude": 37.6409179,
    // 	"longitude": 126.9060441,
    // 	"created_at": "2014-12-29 08:51:37",
    // 	"updated_at": "2014-12-29 08:51:37"
    // }

    const handleChange = (e) => {
        let { name, value } = e.target;
        console.log('name:', name);

        if (
            name === 'weekday_fee' ||
            name === 'weekend_fee' ||
            name === 'cart_fee' ||
            name === 'caddie_fee'
        ) {
            value = formatNumber(parseNumber(value.replace(/[^\d,]/g, '')));
        } else if (
            name === 'reservationAgentTel1' ||
            name === 'reservationAgentTel2' ||
            name === 'reservationAgentTel3'
        ) {
            value = value.replace(/[^0-9]/g, '');
        }

        const updatedData = { ...club, [name]: value };

        // courses 속성을 처리
        if (name.startsWith('course-')) {
            const index = parseInt(name.replace('course-', ''));
            const coursesArray = updatedData.courses.split(',');
            console.log(index);
            console.log(coursesArray);
            coursesArray[index] = value;
            // const updateCourses = coursesArray.join(',');
            updatedData.courses = coursesArray.join(',');
        }

        setClub(updatedData);

        // setClub((prevState) => ({
        //     ...prevState,
        //     [name]: value,
        // }));

        console.log(updatedData);
    };

    const handleRulesChange = (content) => {
        console.log(content);
        setClub({ ...club, rules: content });
    };
    const handlePrivacyChange = (content) => {
        console.log(content);
        setClub({ ...club, privacy: content });
    };
    const handlePlaceboChange = (content) => {
        console.log(content);
        setClub({ ...club, placebo: content });
    };

    const handleAddCourse = () => {
        const coursesArray = club.courses.split(',');
        coursesArray.push('');
        const updateCourses = coursesArray.join(',');

        const addCourse = {
            ...club,
            courses: updateCourses,
        };

        console.log(addCourse);
        setClub(addCourse);
        // const addCourse = club.map((v) => {
        //     const courses = v.courses.split(',');
        //     console.log(courses);
        //     return club;
        // });
        // console.log(addCourse);
    };

    const handleSubmit = () => {
        const parsedData = {
            ...club,
            weekday_fee: parseNumber(club.weekday_fee),
            weekend_fee: parseNumber(club.weekend_fee),
            cart_fee: parseNumber(club.cart_fee),
            caddie_fee: parseNumber(club.caddie_fee),
            recruitment_notice_img_url: club.recruitment_notice_img_url || '',
            rules: editorRefs.current[0].getInstance().getMarkdown(),
            privacy: editorRefs.current[1].getInstance().getMarkdown(),
            placebo: editorRefs.current[2].getInstance().getMarkdown(),
        };

        const params = {
            token: localStorage.getItem('auth-token'),
            data: parsedData,
        };
        axios
            .post(`${apiUrl}/club/admin/update/${getParams.id}`, params)
            .then((res) => {
                console.log(res);
                if (res.data.success) {
                    setSuccessAlert(true);
                } else {
                    setErrorMessage(res.data.message);
                    setErrorAlert(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleUploadImage = (e) => {
        const file = e.target.files[0];

        console.log(file);

        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_S3_ACCESSKEYID,
            secretAccessKey: process.env.REACT_APP_AWS_S3_SECRETACCESSKEY,
            region: process.env.REACT_APP_AWS_S3_REGION,
        });

        const ext = file.name.split('.').pop();

        if (ext !== 'jpg') {
            alert('JPG 파일만 업로드 가능합니다.');
            return false;
        }

        if (file.size > 150000) {
            alert('업로드 파일 용량은 150KB 이하만 가능합니다.');
            return false;
        }

        const uploadParams = {
            Bucket: process.env.REACT_APP_AWS_S3_IMAGE_ROOT,
            ACL: 'public-read',
            Key:
                process.env.REACT_APP_AWS_S3_IMAGE_CLUBS_THUMB_WHERE2USE +
                '/' +
                club.id +
                '/thumb.' +
                ext,
            Body: file,
        };

        s3.upload(uploadParams, (err, data) => {
            if (err) {
                // console.log(err);
                setUploadErrorAlert(true);
            } else {
                setClub({
                    ...club,
                    thumbnail: data.Location + '?v=' + Date.now(),
                });
            }
        });
    };
    const handleUploadRecruitmentImage = (e) => {
        const file = e.target.files[0];

        console.log(file);

        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_S3_ACCESSKEYID,
            secretAccessKey: process.env.REACT_APP_AWS_S3_SECRETACCESSKEY,
            region: process.env.REACT_APP_AWS_S3_REGION,
        });

        const ext = file.name.split('.').pop();
        const allowedExts = ['jpg', 'jpeg', 'png'];
        if (!allowedExts.some((extName) => extName === ext.toLowerCase())) {
            alert('JPG, PNG 파일만 업로드 가능합니다.');
            return false;
        }

        if (file.size > 5 * 1024 * 1024) {
            alert('업로드 파일 용량은 5MB 이하만 가능합니다.');
            return false;
        }
        const uploadParams = {
            Bucket: process.env.REACT_APP_AWS_S3_IMAGE_ROOT,
            ACL: 'public-read',
            Key: 'club-recruitment' + '/' + club.id + `/${Date.now()}` + ext,
            Body: file,
        };

        s3.upload(uploadParams, (err, data) => {
            if (err) {
                // console.log(err);
                setUploadErrorAlert(true);
            } else {
                setClub({
                    ...club,
                    recruitment_notice_img_url: data.Location,
                });
            }
        });
    };

    return (
        <Container maxWidth="true">
            <Typography variant="h4" sx={{ mt: 5, mb: 5 }}>
                골프장 정보 수정
            </Typography>
            {club && (
                <Box component={'form'} autoComplete="off">
                    <Card>
                        <CardContent>
                            <Grid2 container spacing={2}>
                                <Grid2 xs={6}>
                                    <TextField
                                        label="골프장명"
                                        name="name"
                                        value={club.name || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={6}>
                                    <Avatar
                                        src={club.thumbnail}
                                        variant="rounded"
                                        sx={{
                                            float: 'left',
                                            width: '70px',
                                            height: '53px',
                                            mr: 1,
                                        }}
                                    />
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<UploadFile />}
                                        size="large"
                                        onChange={handleUploadImage}
                                        sx={{ float: 'left' }}
                                    >
                                        골프장 대표사진 업로드
                                        <VisuallyHiddenInput type="file" />
                                    </Button>
                                    <Collapse
                                        in={uploadErrorAlert}
                                        sx={{ float: 'left', ml: 1 }}
                                    >
                                        <Alert
                                            variant="filled"
                                            severity="error"
                                        >
                                            업로드 오류!
                                        </Alert>
                                    </Collapse>
                                    <Box
                                        component={'span'}
                                        sx={{ float: 'left', ml: 1 }}
                                    >
                                        업로드 규격
                                        <br />
                                        jpg만 가능, 용량 150kb 이하만
                                    </Box>
                                </Grid2>
                                <Grid2 xs={8}>
                                    <TextField
                                        label="주소"
                                        name="address"
                                        value={club.address || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="위도"
                                        name="latitude"
                                        value={club.latitude || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="경도"
                                        name="longitude"
                                        value={club.longitude || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="club-region-select-label">
                                            골프장지역
                                        </InputLabel>
                                        <Select
                                            labelId="club-region-label"
                                            id="club-region"
                                            name="club_region"
                                            value={club.club_region || ''}
                                            label="골프장지역"
                                            displayEmpty
                                            fullWidth
                                            disabled
                                            onChange={handleChange}
                                        >
                                            {clubRegionArray.map((region) => (
                                                <MenuItem
                                                    key={region.id}
                                                    value={region.id}
                                                >
                                                    {region.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="시도"
                                        name="region"
                                        value={club.region || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="club-region-select-label">
                                            분류
                                        </InputLabel>
                                        <Select
                                            labelId="club-type-label"
                                            id="club-type"
                                            name="club_type"
                                            value={club.club_type || ''}
                                            label="분류"
                                            displayEmpty
                                            fullWidth
                                            disabled
                                            onChange={handleChange}
                                        >
                                            {clubType.map((type, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={type.id}
                                                >
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="대표전화"
                                        name="phone"
                                        value={club.phone || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={4}>
                                    <TextField
                                        label="홈페이지"
                                        name="webpage"
                                        value={club.webpage || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={8}>
                                    <TextField
                                        label="예약부서"
                                        name="reserve_phone"
                                        value={club.reserve_phone || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="이메일"
                                        name="email"
                                        value={club.email || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="팩스"
                                        name="fax"
                                        value={club.fax || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="예약담당자1"
                                        name="reservationAgent1"
                                        value={club.reservationAgent1 || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="예약담당연락처1"
                                        name="reservationAgentTel1"
                                        value={club.reservationAgentTel1 || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="예약담당자2"
                                        name="reservationAgent2"
                                        value={club.reservationAgent2 || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="예약담당연락처2"
                                        name="reservationAgentTel2"
                                        value={club.reservationAgentTel2 || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="예약담당자3"
                                        name="reservationAgent3"
                                        value={club.reservationAgent3 || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="예약담당연락처3"
                                        name="reservationAgentTel3"
                                        value={club.reservationAgentTel3 || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="전체홀수"
                                        name="holes"
                                        value={club.holes || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="그린피 주중"
                                        name="weekday_fee"
                                        value={
                                            formatNumber(club.weekday_fee) || ''
                                        }
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="그린피 주말"
                                        name="weekend_fee"
                                        value={
                                            formatNumber(club.weekend_fee) || ''
                                        }
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="카트피"
                                        name="cart_fee"
                                        value={
                                            formatNumber(club.cart_fee) || ''
                                        }
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        label="캐디피"
                                        name="caddie_fee"
                                        value={
                                            formatNumber(club.caddie_fee) || ''
                                        }
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={2}></Grid2>
                                {club.courses
                                    .split(',')
                                    .map((course, index) => (
                                        <Grid2 xs={2} key={index}>
                                            <TextField
                                                no={index}
                                                label={'코스' + (index + 1)}
                                                name={`course-${index}`}
                                                value={course}
                                                onChange={handleChange}
                                                fullWidth
                                                InputProps={
                                                    index + 1 ===
                                                    club.courses.split(',')
                                                        .length
                                                        ? {
                                                              endAdornment: (
                                                                  <InputAdornment position="end">
                                                                      <IconButton
                                                                          aria-label="add"
                                                                          onClick={
                                                                              handleAddCourse
                                                                          }
                                                                      >
                                                                          <Add />
                                                                      </IconButton>
                                                                  </InputAdornment>
                                                              ),
                                                          }
                                                        : {}
                                                }
                                            />
                                        </Grid2>
                                    ))}

                                <Grid2 xs={12} container sx={{}}>
                                    <Grid2 item>
                                        <TextField
                                            label="입금계좌 은행명"
                                            // name="reservationAgent1"
                                            value={club.account_bank_name || ''}
                                            onChange={(e) => {
                                                setClub((prev) => {
                                                    return {
                                                        ...prev,
                                                        account_bank_name:
                                                            e.target.value,
                                                    };
                                                });
                                            }}
                                            fullWidth
                                        />
                                    </Grid2>
                                    <Grid2 item>
                                        <TextField
                                            label="입금계좌 계좌번호"
                                            // name="reservationAgent1"
                                            value={club.account_number || ''}
                                            onChange={(e) => {
                                                setClub((prev) => {
                                                    return {
                                                        ...prev,
                                                        account_number:
                                                            e.target.value,
                                                    };
                                                });
                                            }}
                                            fullWidth
                                        />
                                    </Grid2>
                                    <Grid2 item>
                                        <TextField
                                            label="입금계좌 예금주"
                                            // name="reservationAgent1"
                                            value={
                                                club.account_depositor_name ||
                                                ''
                                            }
                                            onChange={(e) => {
                                                setClub((prev) => {
                                                    return {
                                                        ...prev,
                                                        account_depositor_name:
                                                            e.target.value,
                                                    };
                                                });
                                            }}
                                            fullWidth
                                        />
                                    </Grid2>
                                </Grid2>
                                <Grid2
                                    xs={12}
                                    container
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Grid2 item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        club.is_year_booking_opened
                                                    }
                                                    onChange={(e) => {
                                                        setClub((prev) => {
                                                            return {
                                                                ...prev,
                                                                is_year_booking_opened:
                                                                    e.target
                                                                        .checked
                                                                        ? 1
                                                                        : 0,
                                                            };
                                                        });
                                                    }}
                                                />
                                            }
                                            label="연단체 오픈"
                                        />
                                    </Grid2>
                                </Grid2>
                                <Grid2
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography sx={{ mb: 1, mt: 2 }}>
                                        연간단체팀 모집공고
                                    </Typography>
                                    <Avatar
                                        src={club.recruitment_notice_img_url}
                                        alt="Remy Sharp"
                                        variant="rounded"
                                        sx={{
                                            mr: 1,
                                            width: '350px',
                                            height: '500px',
                                            mb: 1,
                                        }}
                                    >
                                        NO IMAGE
                                    </Avatar>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<UploadFile />}
                                        size="large"
                                        onChange={handleUploadRecruitmentImage}
                                        sx={{ mr: 1, width: '350px' }}
                                    >
                                        모집공고 업로드
                                        <VisuallyHiddenInput type="file" />
                                    </Button>
                                </Grid2>

                                <Grid2 xs={12}>
                                    <Typography sx={{ mb: 1, mt: 2 }}>
                                        연간단체팀 이용수칙
                                    </Typography>
                                    <Editor
                                        ref={(el) =>
                                            (editorRefs.current[0] = el)
                                        }
                                        initialValue={club.rules || ''}
                                        previewStyle="vertical"
                                        height="400px"
                                        initialEditType="wysiwyg"
                                        useCommandShortcut={true}
                                        autofocus={false}
                                        toolbarItems={[
                                            [
                                                'heading',
                                                'bold',
                                                'italic',
                                                'strike',
                                            ],
                                            ['hr', 'quote'],
                                            [
                                                'ul',
                                                'ol',
                                                'task',
                                                'indent',
                                                'outdent',
                                            ],
                                            ['table', 'link'],
                                            ['code', 'codeblock'],
                                        ]}
                                    />
                                    {/* <ReactQuill
                                        value={club.rules || ''}
                                        placeholder="연간단체팀 이용수칙"
                                        onChange={handleRulesChange}
                                        theme="snow"
                                        style={{
                                            width: '100%',
                                            height: '300px',
                                            marginBottom: '32px',
                                        }}
                                    /> */}
                                </Grid2>
                                <Grid2 xs={12}>
                                    <Typography sx={{ mb: 1, mt: 2 }}>
                                        개인정보 처리방침
                                    </Typography>
                                    <Editor
                                        ref={(el) =>
                                            (editorRefs.current[1] = el)
                                        }
                                        initialValue={club.privacy || ''}
                                        previewStyle="vertical"
                                        height="400px"
                                        initialEditType="wysiwyg"
                                        useCommandShortcut={true}
                                        autofocus={false}
                                        toolbarItems={[
                                            [
                                                'heading',
                                                'bold',
                                                'italic',
                                                'strike',
                                            ],
                                            ['hr', 'quote'],
                                            [
                                                'ul',
                                                'ol',
                                                'task',
                                                'indent',
                                                'outdent',
                                            ],
                                            ['table', 'link'],
                                            ['code', 'codeblock'],
                                        ]}
                                    />
                                    {/* <ReactQuill
                                        value={club.privacy || ''}
                                        placeholder="개인정보 제3자 제공 동의"
                                        onChange={handlePrivacyChange}
                                        theme="snow"
                                        style={{
                                            width: '100%',
                                            height: '300px',
                                            marginBottom: '32px',
                                        }}
                                    /> */}
                                </Grid2>
                                <Grid2 xs={12}>
                                    <Typography sx={{ mb: 1, mt: 2 }}>
                                        취소 및 위약 규정
                                    </Typography>
                                    <Editor
                                        ref={(el) =>
                                            (editorRefs.current[2] = el)
                                        }
                                        initialValue={club.placebo || ''}
                                        previewStyle="vertical"
                                        height="400px"
                                        initialEditType="wysiwyg"
                                        useCommandShortcut={true}
                                        autofocus={false}
                                        toolbarItems={[
                                            [
                                                'heading',
                                                'bold',
                                                'italic',
                                                'strike',
                                            ],
                                            ['hr', 'quote'],
                                            [
                                                'ul',
                                                'ol',
                                                'task',
                                                'indent',
                                                'outdent',
                                            ],
                                            ['table', 'link'],
                                            ['code', 'codeblock'],
                                        ]}
                                    />
                                    {/* <ReactQuill
                                        value={club.placebo || ''}
                                        placeholder="취소 및 위약 규정"
                                        onChange={handlePlaceboChange}
                                        theme="snow"
                                        style={{
                                            width: '100%',
                                            height: '300px',
                                            marginBottom: '32px',
                                        }}
                                    /> */}
                                </Grid2>
                                {/* <Grid2 xs={12}>
                                    <TextField
                                        id="rules-multiline"
                                        label="연간단체팀 이용수칙"
                                        name="rules"
                                        value={club.rules || ''}
                                        onChange={handleChange}
                                        multiline
                                        rows={4}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={12}>
                                    <TextField
                                        id="privacy-multiline"
                                        label="개인정보 제3자 제공 동의"
                                        name="privacy"
                                        value={club.privacy || ''}
                                        onChange={handleChange}
                                        multiline
                                        rows={4}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 xs={12}>
                                    <TextField
                                        id="placebo-multiline"
                                        label="취소 및 위약 규정"
                                        name="placebo"
                                        value={club.placebo || ''}
                                        onChange={handleChange}
                                        multiline
                                        rows={4}
                                        fullWidth
                                    />
                                </Grid2> */}

                                {/* <Grid2 xs={4}>
                                    <Select
                                        labelId="cities-region-label"
                                        id="cities-region"
                                        value={club.club_region}
                                        size="small"
                                        displayEmpty
                                        onChange={handleChange}
                                    >
                                        {cities.map((city) => (
                                            <MenuItem
                                                key={city.id}
                                                value={city.id}
                                            >
                                                {city.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid2> */}
                            </Grid2>
                        </CardContent>
                        {/* <Divider /> */}
                        <Box>
                            <Collapse in={successAlert}>
                                <Alert
                                    variant="filled"
                                    severity="success"
                                    sx={{ m: 2 }}
                                >
                                    수정완료
                                </Alert>
                            </Collapse>
                            <Collapse in={errorAlert}>
                                <Alert
                                    variant="filled"
                                    severity="error"
                                    sx={{ m: 2 }}
                                >
                                    {errorMessage}
                                </Alert>
                            </Collapse>
                        </Box>
                        <CardActions
                            sx={{ ml: 1, mb: 2, justifyContent: 'flex-start' }}
                        >
                            <Button
                                variant="contained"
                                color="inherit"
                                size="large"
                                onClick={handleSubmit}
                            >
                                수정
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
            )}
        </Container>
    );
}
