import { useRecoilState } from 'recoil';
import { kakaoState } from '../recoil/kakaoState';
import { useEffect } from 'react';

export default function useKakao() {
    const [ks, setKakaoState] = useRecoilState(kakaoState);

    useEffect(() => {
        if (ks) {
            return;
        }
        const script = document.createElement('script');
        script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.7.4/kakao.min.js';
        script.integrity =
            'sha384-DKYJZ8NLiK8MN4/C5P2dtSmLQ4KwPaoqAfyA/DfmEc1VDxu4yyC7wy6K1Hs90nka';
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);

        script.addEventListener('load', (e) => {
            if (window.Kakao?.init) {
                window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
                window.Kakao.teeshot = {};
                window.Kakao.teeshot.chat = () => {
                    window.Kakao.Channel.chat({
                        channelPublicId: process.env.REACT_APP_KAKAO_CHANNEL_ID,
                    });
                };

                setKakaoState(window.Kakao);
            }
        });
    }, []);
}
