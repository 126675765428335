import { Box, Divider, Stack } from '@mui/material';
import { alpha, css } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { NAV } from './config-layout';
import Scrollbar from '../component/scrollbar/scrollbar';
import { usePathname } from '../routes/hooks';
import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { authState } from '../recoil/authState';
import { useTheme } from '@emotion/react';
import { navData, useBottomNavData } from './navData';
import { ListItemButton } from '@mui/material';

const NavItem = ({ item, fullPath, theme, isActive, hideParent }) => {
    // if (!item.child || item.child.length === 0) {
    if (item.isMenuItem) {
        // 최종 자식

        const ListITemBtnSx = {
            minHeight: 44,
            borderRadius: 0.75,
            textTransform: 'capitalize',
            fontWeight: 'fontWeightMedium',
            '&:hover': {
                bgcolor: `${alpha(theme.palette.common.white, 0.1)} !important`,
            },
            ...(isActive && {
                fontWeight: 'fontWeightSemiBold',
                bgcolor: `${alpha(
                    theme.palette.common.white,
                    0.15
                )} !important`,
                // bgcolor: `${alpha(
                //     theme.palette.primary.main,
                //     0.55
                // )} !important`,
                '&:hover': {
                    bgcolor: `${alpha(
                        theme.palette.common.white,
                        0.2
                    )} !important`,
                },
            }),
        };

        if (item.isBtn) {
            return (
                <ListItemButton
                    sx={ListITemBtnSx}
                    onClick={() => {
                        item.onClick();
                    }}
                >
                    <Box
                        component="span"
                        sx={{
                            color: 'theme.palette.common.white',
                            fontSize: '16px',
                        }}
                    >
                        {item.name}
                    </Box>
                </ListItemButton>
            );
        }

        return (
            <>
                <Link to={fullPath} style={{ textDecoration: 'none' }}>
                    <ListItemButton sx={ListITemBtnSx}>
                        <Box
                            component="span"
                            sx={{
                                color: 'theme.palette.common.white',
                                fontSize: '16px',
                            }}
                        >
                            {item.name}
                        </Box>
                    </ListItemButton>
                </Link>
            </>
        );
    }

    if (hideParent) {
        return null;
    }

    if (item.isTopParent) {
        return (
            <>
                <Divider
                    sx={{
                        borderBottomWidth: '1px',
                        backgroundColor: alpha(theme.palette.common.white, 1),
                    }}
                />
                <Box
                    sx={{
                        mt: 1,
                        minHeight: 30,
                        fontSize: '12px',
                        borderRadius: 0.75,
                        fontWeight: 'fontWeightMedium',
                        display: 'flex',
                        alignItems: 'center',
                        paddingX: 2,
                        color: alpha(theme.palette.common.white, 1),
                    }}
                >
                    {item.name}{' '}
                </Box>
            </>
        );
    }
    // 부모
};

const RecursiveNav = ({
    items,
    parentPath = '',
    pathname,
    theme,
    isMasterAuth,
    hideParent,
}) => {
    return (
        <Stack spacing={0.5}>
            {items
                .filter((item) => !item.requiresMasterAuth || isMasterAuth)
                .map((item) => {
                    const fullPath = `${parentPath}/${item.key}`; // Construct full path
                    const isActive = pathname.startsWith(fullPath);

                    return (
                        <Box key={item.key}>
                            <NavItem
                                isParent={item.child?.length > 0}
                                item={item}
                                fullPath={fullPath}
                                theme={theme}
                                isActive={isActive}
                                hideParent={hideParent}
                            />
                            {item.child?.length > 0 && (
                                <Box>
                                    <RecursiveNav
                                        hideParent={hideParent}
                                        items={item.child}
                                        parentPath={fullPath} // Pass the full path to children
                                        pathname={pathname}
                                        theme={theme}
                                        isMasterAuth={isMasterAuth}
                                    />
                                </Box>
                            )}
                        </Box>
                    );
                })}
        </Stack>
    );
};

export default function Nav() {
    const pathname = usePathname();
    const auth = useRecoilValue(authState);
    const isMasterAuth = auth.user.club_admin_level === 10;
    const theme = useTheme();
    const bottomNavData = useBottomNavData();

    return (
        <Box
            sx={{
                // flexShrink: { lg: 0 },
                // width: { lg: NAV.WIDTH },
                width: NAV.WIDTH,
                '*': { color: '#FAF9F6' },
            }}
        >
            <Box
                width={280}
                height="100%"
                position="fixed"
                sx={{
                    position: 'fixed',
                }}
            >
                <Scrollbar
                    sx={{
                        height: 1,
                        '& .simplebar-content': {
                            height: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: theme.palette.primary.dark,
                        },
                    }}
                >
                    {/* Main Navigation Section */}
                    <Box
                        sx={{
                            fontSize: 18,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            minHeight: '100px',
                            alignItems: 'center',
                        }}
                    >
                        <Link
                            to="/"
                            style={{
                                textDecoration: 'none',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                // gap:"5px"
                            }}
                        >
                            <Box
                                sx={{
                                    // margin: '0 auto',
                                    width: `80px`,
                                    height: `80px`,
                                    marginLeft: '-15px',
                                    backgroundPosition: 'center',

                                    // back
                                    backgroundSize: 'cover',

                                    // backgrond ,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: 'url(/teeshot_logo.png)',
                                }}
                            >
                                {' '}
                            </Box>
                            <Stack sx={{ fontWeight: 'bold', flex: 1 }}>
                                Teeshot 부킹 관리자
                            </Stack>
                            {/* <img src="/teeshot-logo.svg" /> */}

                            {/* HBMP 부킹 관리자 */}
                        </Link>
                    </Box>
                    <Stack
                        component="nav"
                        spacing={0.5}
                        sx={{ px: 2, flex: 1 }}
                    >
                        <RecursiveNav
                            items={navData}
                            pathname={pathname}
                            theme={theme}
                            isMasterAuth={isMasterAuth}
                        />
                    </Stack>

                    {/* Bottom Navigation Section */}
                    <Box
                        sx={{
                            bgcolor: theme.palette.primary.darker,
                            padding: 1,
                        }}
                    >
                        <Stack component="nav" spacing={0.5}>
                            <RecursiveNav
                                hideParent={true}
                                items={bottomNavData}
                                pathname={pathname}
                                theme={theme}
                                isMasterAuth={isMasterAuth}
                            />
                        </Stack>
                    </Box>
                </Scrollbar>
            </Box>
        </Box>
    );
}
