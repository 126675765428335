import { CircularProgress, Stack } from '@mui/material';
import React from 'react';

function PageLoadingCircle() {
    return (
        <Stack
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 200px)',
            }}
        >
            <CircularProgress size={"60px"} color="primary" />
        </Stack>
    );
}

export default PageLoadingCircle;
