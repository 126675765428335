import { Box } from '@mui/material';

import { HEADER, NAV } from './config-layout';
import { useResponsive } from '../hooks/use-responsive';
import { useTheme } from '@emotion/react';

const SPACING = 8;

export default function Main({ children, sx, ...other }) {
    const lgUp = useResponsive('up', 'lg');
    const theme = useTheme();
    return (
        <Box
            component={'main'}
            sx={{
                flexGrow: 1,
                minHeight: 1,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.common.white,
                px: 2,
                py: `${HEADER.H_DESKTOP + SPACING}px`,
                width: `calc(100% - ${NAV.WIDTH}px)`,
                ...sx,
            }}
            {...other}
        >
            {children}
        </Box>
    );
}
