import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Home() {
    const navigate = useNavigate();

    useEffect(() => {
        // if (!localStorage.getItem('auth-token')) {
        //     navigate('login');
        // }
        navigate('yb/yearBookingList');
    });

    return (
        <>
            <div>Home!</div>
        </>
    );
}
