import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './global.css';
// import App from './App';

import {
    BrowserRouter,
    Outlet,
    RouterProvider,
    createBrowserRouter,
} from 'react-router-dom';

import Home from './pages/dashBoard/Home';
import BookingDiscount from './pages/bookingDiscount';
import Login from './pages/Login';
import Repassword from './pages/Repassword';
import Layout from './layouts';
import GolfInfomation from './pages/golfInformation';
import GolfAcount from './pages/golfAccount';
import ThemeProvider from './theme';
import BookingDiscountCreate from './pages/bookingDiscount/create';
import GolfInfomationModify from './pages/golfInformation/modify';
import BookingDiscountReservation from './pages/bookingDiscount/reservation';
import { StatusProvider } from './contexts/statusContext';
import { HelmetProvider } from 'react-helmet-async';

import { RecoilRoot } from 'recoil';
import App from './App';
import Meta from 'component/Meta';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <HelmetProvider>
        <Meta />
        <RecoilRoot>
            <BrowserRouter>
                <Suspense>
                    <App />
                </Suspense>
            </BrowserRouter>
        </RecoilRoot>
    </HelmetProvider>
);

// root.render(
//     <StatusProvider>
//         <ThemeProvider>
//             <RouterProvider router={router} />
//         </ThemeProvider>
//     </StatusProvider>
// );
