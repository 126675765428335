import { Helmet } from 'react-helmet-async';

export default function Meta() {
    return (
        <Helmet>
            <meta http-equiv="Expires" content="0"></meta>
            <meta
                http-equiv="Cache-Control"
                content="no-cache, no-store, must-revalidate"
            />

            <meta
                property="og:url"
                content="https://booking-golfclub.dev.teeshot.co.kr/"
            />
            <meta property="og:title" content="티샷 부킹관리자" />
            <meta property="og:type" content="website" />
            <meta
                property="og:image"
                content="https://api.v2.teeshot.co.kr/event/resource/images/teeshot/logo_long_green.jpg"
            />
            <meta property="og:description" content="궁금한 점을 채팅으로 물어봐주세요." />

            <title>티샷 - 부킹관리자</title>
        </Helmet>
    );
}
