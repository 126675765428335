import useKakao from 'hooks/useKakao';
import { StatusProvider } from './contexts/statusContext';
import Router from './routes/Router';
import ThemeProvider from './theme';
import 'react-datepicker/dist/react-datepicker.css';

export default function App() {
    useKakao();
    return (
        <StatusProvider>
            <ThemeProvider>
                <Router />
            </ThemeProvider>
        </StatusProvider>
    );
}
