import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { kakaoState } from 'recoil/kakaoState';

const navData = [
    {
        key: 'yb',
        name: '연단체 부킹',
        isTopParent: true,
        child: [
            {
                key: 'yearBookingList',
                isMenuItem: true,
                name: '신청내역',
                child: [{ key: ':id', name: '신청상세' }],
            },
            {
                key: 'yearBookingRoundingList',
                isMenuItem: true,
                name: '매월 라운드 일정',
                child: [{ key: ':id', name: '라운드상세' }],
            },
            {
                key: 'yearBookingGroups',
                isMenuItem: true,

                name: '단체관리',
                child: [{ key: ':id', name: '단체상세' }],
            },
            // {
            //     key: 'noYearBookingGroups',
            //     isMenuItem: true,
            //     name: '미신청 단체',
            //     child: [],
            //     requiresMasterAuth: true,
            // },
        ],
    },
    {
        key: 'dc',
        isTopParent: true,
        name: '할인 부킹',
        child: [
            {
                key: 'bookingdiscountList',
                name: '할인부킹 관리',

                isMenuItem: true,
                child: [
                    {
                        key: 'create',
                        name: '할인부킹 등록',
                        child: [{ key: ':id', name: '상세' }],
                    },
                ],
            },
            {
                key: 'bookingdiscountrsvps',
                name: '할인부킹 예약 관리',
                isMenuItem: true,

                child: [],
            },
        ],
    },
];

const useBottomNavData = () => {
    const [Kakao] = useRecoilState(kakaoState);

    const bottomNavData = [
        {
            key: 'common',
            name: '일반',
            child: [
                {
                    key: 'golfinfo',
                    name: '골프장 관리',
                    child: [
                        {
                            key: 'modify',
                            name: '정보 수정',
                            child: [{ key: ':id', name: '상세' }],
                        },
                    ],

                    isMenuItem: true,
                },
                {
                    key: 'golfacount',
                    name: '골프장 계정 관리',
                    child: [],

                    isMenuItem: true,
                },
                {
                    key: 'chat',
                    name: (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={1}
                            sx={{
                                img: {
                                    width: '25px',
                                },
                            }}
                        >
                            <img src="/kakao.png" /> 이용문의
                        </Stack>
                    ),
                    isBtn: true,
                    onClick: () => {
                        // alert('hi');
                        Kakao.teeshot.chat();
                    },
                    child: [],
                    isMenuItem: true,
                },
                {
                    key: 'logList',
                    name: '접속로그',
                    child: [],
                    requiresMasterAuth: true,
                    isMenuItem: true,
                },
            ],
        },
    ];

    return bottomNavData;
};

export { navData, useBottomNavData };
