import {
    Box,
    Button,
    Card,
    Container,
    Divider,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    applyFilter,
    emptyRows,
    getComparator,
} from '../../golfInformation/view/utils';
import BookingTableToolbar from './booking-table-toolbar';
import Scrollbar from '../../../component/scrollbar';
import BookingTableHead from './booking-table-head';
import BookingTableRow from './booking-table-row';
import TableEmptyRows from '../../golfInformation/view/table-empty-rows';
import TableNoData from '../../golfInformation/view/table-no-data';
import StatusBox from '../../../component/statusBox';
import ModalConfirm from '../../../component/modalConfirm';
import moment from 'moment';
import useFetchData from '../../../hooks/use-fetchData';
import usePostData from '../../../hooks/use-postData';
import { useRecoilValue } from 'recoil';
import { authState } from '../../../recoil/authState';
import API from '../../../utils/api';
import TableCard from 'component/TableCard';

export default function BookingDiscountReservationView() {
    const auth = useRecoilValue(authState);

    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const today = new Date();

    const me = auth.user;

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('id');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [filterField, setFilterField] = useState('club_name');

    const [discountLists, setDiscountLists] = useState([]);

    const [searchData, setSearchData] = useState({
        range: 'all',
        rangeStart: moment(today).format('YYYY-MM-DD'),
        rangeEnd: moment(today).format('YYYY-MM-DD'),
        status: 'all',
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalOnSuccess, setModalOnSuccess] = useState(null);

    const { fetchData } = useFetchData();
    const { postData } = usePostData();

    const getDataRef = useRef(fetchData);

    // useEffect(() => {
    //     if (!localStorage.getItem('auth-token')) {
    //         navigate('/login');
    //     }
    // });

    // useEffect(() => {
    //     setMe(JSON.parse(localStorage.getItem('me')));
    // }, []);

    const getReservation = useCallback(
        async (data = searchData) => {
            const obj = {
                limit: '',
                offset: '',
                token: localStorage.getItem('auth-token'),
                user: me,
            };
            console.log('searchData: ', data);
            console.log('obj: ', obj);

            const params = { ...obj, ...data };

            console.log('params: ', params);
            axios
                .get(`${apiUrl}/club/admin/discountRsvpsLists`, {
                    params,
                })
                .then((res) => {
                    console.log(res);
                    const result = res.data;
                    if (result.success) {
                        setDiscountLists(result.data);
                    } else {
                        alert(result.message);

                        if (result.code === 1000) {
                            localStorage.clear();
                            navigate('/login');
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        [me, navigate, searchData]
    );

    const handleSort = (event, id) => {
        const isAsc = orderBy === id && order === 'asc';
        if (id !== '') {
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(id);
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = discountLists.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleReservationCanceled = (item) => {
        console.log(item);
        const updateData = discountLists.map((list) => {
            if (list.id === item.id) {
                let updateItem = list;
                updateItem.reservation_status = 5;

                return updateItem;
            }
            return list;
        });
        console.log(updateData);
        setDiscountLists(updateData);
    };

    const handleReservationSuccess = (item) => {
        console.log('handleReservationSuccess');
        console.log(item);
        const updateData = discountLists.map((list) => {
            if (list.id === item.id) {
                let updateItem = list;
                updateItem.reservation_status = 3;

                return updateItem;
            }
            return list;
        });
        console.log(updateData);
        setDiscountLists(updateData);
    };

    const handleSearchButton = (data) => {
        console.log(data);
        setSearchData(data);
        // getClubs(data);
    };

    const statusButton = (status, item) => {
        const expired = today > new Date(item.golf_time);
        if (!expired && status === 2) {
            return (
                <Box component="div" sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => statusButtonClick(item, 'canceled')}
                        sx={{ m: 0.5 }}
                    >
                        취소
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => statusButtonClick(item, 'confirmed')}
                        sx={{ m: 0.5 }}
                    >
                        확정
                    </Button>
                </Box>
            );
        } else if (!expired && status === 3) {
            return (
                <Box component="div">
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => statusButtonClick(item, 'canceled')}
                    >
                        취소
                    </Button>
                </Box>
            );
        }
    };

    const statusButtonClick = (item, state) => {
        if (state === 'canceled') {
            setModalTitle('예약 취소');
        } else if (state === 'confirmed') {
            setModalTitle('예약 확정');
        }
        setModalOnSuccess(() => () => postStateTransition(item, state));
        setModalOpen(true);
    };

    const postStateTransition = (item, state) => {
        const params = {
            // token: localStorage.getItem('auth-token'),
            state: state,
            data: item,
        };

        postData(API.DISCOUNT_STATE_TRANSITION, params, () => {
            navigate(0);
        });
        // axios
        //     .post(
        //         `${apiUrl}/club/admin/stateTransition`,
        //         params
        //     )
        //     .then((res) => {
        //         console.log(res);
        //         const data = res.data;
        //         if (data.success) {
        //             navigate(0);
        //         } else {
        //             alert(data.message);
        //         }
        //     });
    };

    const dataFiltered = applyFilter({
        inputData: discountLists,
        comparator: getComparator(order, orderBy),
        filterName,
        field: filterField,
    });

    const notFound = !dataFiltered.length && !!filterName;

    // useEffect(() => {
    //     console.log('useEffect');
    //     if (me) {
    //         console.log('me:', me);
    //         getReservation();
    //     }
    // }, [getReservation, me]);

    useEffect(() => {
        getDataRef.current = fetchData;
    }, [fetchData]);

    useEffect(() => {
        getDataRef.current(
            API.DISCOUNT_RSVPS_LISTS,
            { ...{ user: me }, ...searchData },
            (data) => {
                setDiscountLists(data);
            }
        );
    }, [me, searchData]);

    return (
        <Container maxWidth="true">
            <Typography variant="h4" sx={{ mt: 5, mb: 5 }}>
                할인부킹 예약 관리
            </Typography>
            <Divider />
            {/* <Card> */}
            <BookingTableToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                onSearchButton={handleSearchButton}
            />
            <TableCard>
                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }}>
                        <Table sx={{ minWidth: 800 }}>
                            <BookingTableHead
                                order={order}
                                orderBy={orderBy}
                                rowCount={discountLists.length}
                                numSelected={selected.length}
                                onRequestSort={handleSort}
                                onSelectAllClick={handleSelectAllClick}
                                headLabel={[
                                    { id: 'id', label: 'No' },
                                    me.club_admin_level === 10
                                        ? { id: 'club_name', label: '골프장' }
                                        : undefined,
                                    {
                                        id: 'courses',
                                        label: '코스',
                                        minWidth: '100px',
                                    },
                                    {
                                        id: 'holes',
                                        label: '홀수',
                                        minWidth: '70px',
                                    },
                                    {
                                        id: 'golf_time',
                                        label: '부킹일시',
                                        width: '100px',
                                        minWidth: '90px',
                                    },
                                    { id: 'green_fee', label: '그린피' },
                                    {
                                        id: 'green_fee_discount',
                                        label: '할인금액',
                                        minWidth: '80px',
                                    },
                                    { id: 'cart_fee', label: '카트피' },
                                    { id: 'caddie_fee', label: '캐디피' },
                                    {
                                        id: 'golf_people',
                                        label: '인원',
                                        minWidth: '80px',
                                    },
                                    // { id: 'reservation_number', label: '예약' },
                                    {
                                        id: 'realname',
                                        label: '예약자',
                                        minWidth: '80px',
                                    },
                                    { id: 'phone', label: '연락처' },
                                    {
                                        id: 'created_at',
                                        label: '예약일시',
                                        width: '100px',
                                        minWidth: '90px',
                                    },
                                    {
                                        id: 'deleted_at',
                                        label: '취소일시',
                                        width: '100px',
                                        minWidth: '90px',
                                    },
                                    {
                                        id: 'cancel_deadline',
                                        label: '취소시한',
                                        width: '100px',
                                        minWidth: '90px',
                                    },
                                    {
                                        id: 'reservation_status',
                                        label: '상태',
                                        minWidth: '45px',
                                    },
                                    { id: 'process', label: '처리' },
                                    // { id: '' },
                                ]}
                            />
                            <TableBody>
                                {dataFiltered
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row) => (
                                        <BookingTableRow
                                            key={row.id}
                                            selected={
                                                selected.indexOf(row.id) !== -1
                                            }
                                            handleClick={(event) =>
                                                handleClick(event, row.id)
                                            }
                                            rowValue={[
                                                row.id,
                                                me.club_admin_level === 10
                                                    ? row.club_name
                                                    : undefined,
                                                row.courses,
                                                row.holes,
                                                row.golf_time,
                                                new Intl.NumberFormat().format(
                                                    row.green_fee
                                                ),
                                                new Intl.NumberFormat().format(
                                                    row.green_fee_discount
                                                ),
                                                new Intl.NumberFormat().format(
                                                    row.cart_fee
                                                ),
                                                new Intl.NumberFormat().format(
                                                    row.caddie_fee
                                                ),
                                                row.golf_people +
                                                    (row.golf_people === 4
                                                        ? '인필수'
                                                        : '인이상'),
                                                row.realname,
                                                row.phone,
                                                row.created_at,
                                                row.deleted_at,
                                                row.cancel_deadline,
                                                <StatusBox
                                                    status={
                                                        row.reservation_status
                                                    }
                                                    type="text"
                                                />,
                                                statusButton(
                                                    row.reservation_status,
                                                    row
                                                ),
                                            ]}
                                        />
                                    ))}

                                <TableEmptyRows
                                    height={77}
                                    emptyRows={emptyRows(
                                        page,
                                        rowsPerPage,
                                        discountLists.length
                                    )}
                                />

                                {notFound && <TableNoData query={filterName} />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    page={page}
                    component="div"
                    count={discountLists.length}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[50, 100, 200]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableCard>

            {/* </Card> */}
            <ModalConfirm
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                title={modalTitle}
                content={modalTitle + ' 하시겠습니까?'}
                closeText={'닫기'}
                successText={'확인'}
                onCloseClick={() => setModalOpen(false)}
                onSuccessClick={modalOnSuccess}
            />
        </Container>
    );
}
