import { Stack } from '@mui/material';

const TableCard = (props) => {
    return (
        <Stack
            boxShadow={3}
            sx={{
                borderRadius: '6px',
                border: `1px solid #d2e4d6`,
                overflow: 'auto',
            }}
        >
            {props.children}
        </Stack>
    );
};
export default TableCard;
