import { CheckBox } from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    Card,
    Checkbox,
    Chip,
    CircularProgress,
    Collapse,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BenefitCheckBox from './benefit-checkbox';
import moment from 'moment';
import { produce, enableMapSet } from 'immer';
import ModalConfirm from '../../../component/modalConfirm';
import { useRecoilValue } from 'recoil';
import { authState } from '../../../recoil/authState';
import useFetchData from '../../../hooks/use-fetchData';
import API from '../../../utils/api';
import usePostData from '../../../hooks/use-postData';
import { formatNumber, parseNumber, commaNumber } from '../../../utils/common';

enableMapSet();

// const formatNumber = (number) => {
//     return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// };

// const parseNumber = (formattedNumber) => {
//     return formattedNumber.replace(/,/g, '');
// };

export default function BookingDiscountCreateView() {
    const auth = useRecoilValue(authState);

    const me = auth.user;

    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const getParams = useParams();

    // const [me, setMe] = useState('');

    const [club, setClub] = useState([]);
    const [courses, setCourses] = useState('');
    const [people, setPeople] = useState(4);
    const [holes, setHoles] = useState(18);
    const [cancelDay, setCancelDay] = useState('');
    const [cancelHour, setCancelHour] = useState('');
    const [cancelNotPossible, setCancelNotPossible] = useState(0);
    const [cancelNotPossibleChecked, setCancelNotPossibleChecked] =
        useState(false);
    const [bookingDate, setBookingDate] = useState('');
    const [bookingEndDate, setBookingEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [intervalMinute, setIntervalMinute] = useState(7);
    const [greenfee, setGreenfee] = useState('');
    const [greenfeeDiscount, setGreenfeeDiscount] = useState('');
    const [cartfee, setCartfee] = useState('');
    const [caddiefee, setCaddiefee] = useState('');
    const [benefitEtc, setBenefitEtc] = useState('');
    const [benefitEtcChecked, setBenefitEtcChecked] = useState(false);
    const [temporary, setTemporary] = useState([]);
    const [loading, setLoading] = useState(false);

    // const [checkItems, setCheckItems] = useState(new Set());
    const [checkItems, setCheckItems] = useState([]);

    const [selected, setSelected] = useState([]);

    const [errorAlert, setErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalOnSuccess, setModalOnSuccess] = useState(null);

    const benefitItems = [
        '커피제공',
        '조식제공',
        '라운드전 식사 제공',
        '컬러볼 제공',
        '카트피 할인',
    ];

    // useEffect(() => {
    //     setMe(JSON.parse(localStorage.getItem('me')));
    // }, []);

    const { fetchData } = useFetchData();
    const { postData } = usePostData();

    const getDataRef = useRef(fetchData);

    useEffect(() => {
        if (
            me.club_admin_level !== 10 &&
            me.club_id !== parseInt(getParams.id)
        ) {
            alert('골프 회원 정보가 일치하지 않습니다.');
            navigate('/dc/bookingdiscount');
        } else {
            getDataRef.current = fetchData;
        }
    }, [fetchData, getParams.id, me, navigate]);

    useEffect(() => {
        getDataRef.current(
            `${API.DISCOUNT_CLUB_INFO}/${getParams.id}`,
            {},
            (data) => {
                console.log('data: ', data);
                setClub(data);
            }
        );
    }, [getParams.id]);

    // useEffect(() => {
    //     console.log('useEffect');
    //     if (me) {
    //         if (
    //             me.club_admin_level !== 10 &&
    //             me.club_id !== parseInt(getParams.id)
    //         ) {
    //             alert('골프 회원 정보가 일치하지 않습니다.');
    //             navigate('/bookingdiscount');
    //         }
    //         getClub(getParams.id);
    //     }
    // }, [me, getParams.id, navigate]);

    // const getClub = async (id) => {
    //     const params = {
    //         token: localStorage.getItem('auth-token'),
    //     };
    //     const lists = await axios.get(`${apiUrl}/club/admin/info/${id}`, {
    //         params,
    //     });
    //     console.log(lists);
    //     const res = lists.data;
    //     if (res.success) {
    //         const data = res.data;
    //         console.log(data);
    //         setClub(data);
    //         // setCourses(data[0].courses);
    //     }
    // };

    const coursesChange = (event) => {
        setCourses(event.target.value);
    };
    const peopleChange = (event) => {
        setPeople(event.target.value);
    };
    const holesChange = (event) => {
        setHoles(event.target.value);
    };
    const cancelDayChange = (event) => {
        setCancelDay(event.target.value);
    };
    const cancelHourChange = (event) => {
        setCancelHour(event.target.value);
    };
    const handleCancelNotPossible = (event) => {
        setCancelNotPossibleChecked(!cancelNotPossibleChecked);
        if (event.target.checked) {
            setCancelNotPossible(1);
            setCancelDay(0);
            setCancelHour(0);
        } else {
            setCancelNotPossible(0);
            setCancelDay('');
            setCancelHour('');
        }
    };
    const bookingDateChange = (event) => {
        setBookingDate(event.target.value);
    };
    const bookingEndDateChange = (event) => {
        setBookingEndDate(event.target.value);
    };
    const startTimeChange = (event) => {
        setStartTime(event.target.value);
    };
    const endTimeChange = (event) => {
        setEndTime(event.target.value);
    };
    const intervalMinuteChange = (event) => {
        setIntervalMinute(event.target.value);
    };
    const greenfeeChange = (event) => {
        setGreenfee(commaNumber(event.target.value));
    };
    const greenfeeDiscountChange = (event) => {
        setGreenfeeDiscount(commaNumber(event.target.value));
    };
    const cartfeeChange = (event) => {
        setCartfee(commaNumber(event.target.value));
    };
    const caddiefeeChange = (event) => {
        setCaddiefee(commaNumber(event.target.value));
    };
    const benefitEtcChange = (event) => {
        const checkItemsEtc = checkItems.filter((v) => v !== benefitEtc);
        if (benefitEtcChecked) {
            setCheckItems([...checkItemsEtc, event.target.value]);
        }
        setBenefitEtc(event.target.value);
    };

    const handleBenefitEtcChecked = (event) => {
        setBenefitEtcChecked(!benefitEtcChecked);
        checkItemHandler(event.target.value, event.target.checked);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = temporary.map((n) => n.num);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, num) => {
        const selectedIndex = selected.indexOf(num);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, num);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    // 체크된 항목을 삭제하는 함수
    const deleteCheckedItems = () => {
        const filteredData = temporary.filter(
            (item) => !selected.includes(item.num)
        );
        setTemporary(filteredData);
        setSelected([]);
        setModalOpen(false);
    };

    const checkItemHandler = (id, isChecked) => {
        if (isChecked) {
            if (id === '직접입력') {
                console.log('benefitEtc: ', benefitEtc);
                if (benefitEtc) {
                    id = benefitEtc;
                } else {
                    id = '';
                }
            }
            if (id) {
                setCheckItems([...checkItems, id]);
            }
        } else if (!isChecked) {
            if (id === '직접입력') {
                id = benefitEtc;
            }
            setCheckItems(checkItems.filter((v) => v !== id));
        }
    };

    const updateField = (item, field, value) => {
        return produce(item, (draft) => {
            if (field === 'benefit') {
                value = draft.benefit.filter((v) => v !== value);
            } else if (
                field === 'greenfee' ||
                field === 'greenfeeDiscount' ||
                field === 'cartfee' ||
                field === 'caddiefee'
            ) {
                value = commaNumber(value);
            }
            draft[field] = value;
        });
    };

    // const tempUpdate = (num, benefitToRemove, holes) => {
    const tempUpdate = (num, field, value) => {
        const updateData = temporary.map((item) => {
            if (item.num === num) {
                let updatedItem = item;
                updatedItem = updateField(updatedItem, field, value);
                return updatedItem;
            }
            return item;
        });
        setTemporary(updateData);
    };

    // 데이터 생성 함수
    const generateData = (num, currentTime, endTime, interval, cancelDate) => {
        const data = [];
        while (currentTime <= endTime) {
            data.push({
                num,
                club_id: club[0]?.id,
                golf_club_id: club[0]?.club_code,
                club_region: club[0]?.region,
                club_name: club[0]?.name,
                courses: courses,
                holes: holes,
                people: people,
                // bookingDate: bookingDate,
                bookingDate: moment(currentTime).format('YYYY-MM-DD'),
                bookingHour: moment(currentTime).format('HH:mm'),
                cancelDay: moment(cancelDate).format('YYYY-MM-DD HH:mm'),
                cancelNotPossible: cancelNotPossible,
                greenfee: greenfee,
                greenfeeDiscount: greenfeeDiscount,
                cartfee: cartfee,
                caddiefee: caddiefee,
                benefit: checkItems,
                // time: formattedTime,
            });
            currentTime.setTime(currentTime.getTime() + interval);
            num++;
        }

        return data;
    };

    const temporaryCreate = () => {
        setErrorAlert(false);

        if (!courses) {
            alert('시작코스를 선택 하세요.');
            return;
        }
        if (!cancelNotPossible) {
            if (!cancelDay) {
                alert('취소시한 일자를 입력하세요.');
                return;
            }
            if (!cancelHour) {
                alert('취소시한 시간을 입력하세요.');
                return;
            }
        }
        if (!bookingDate) {
            alert('날짜를 선택 하세요.');
            return;
        }
        if (!bookingEndDate) {
            alert('날짜를 선택 하세요.');
            return;
        }
        if (!startTime || !endTime) {
            alert('시간을 선택 하세요.');
            return;
        }

        if (!greenfee) {
            alert('그린피(정가)를 입력하세요.');
            return;
        }
        if (!greenfeeDiscount) {
            alert('할인 그린피를 입력하세요.');
            return;
        }
        if (parseInt(greenfee) < parseInt(greenfeeDiscount)) {
            alert('할인 그린피가 정가보다 높습니다.');
            return;
        }
        if (!cartfee) {
            alert('카트피를 입력하세요.');
            return;
        }
        if (!caddiefee) {
            alert('캐디피를 입력하세요.');
            return;
        }

        // if (diffTime <= 0 || diffTime - intervalTime < 0) {
        //     alert('부킹 시작시간과 종료시간을 다시 확인해주세요');
        //     return;
        // }

        const startDate = new Date(bookingDate);
        const endDate = new Date(bookingEndDate);

        const intervalTime = intervalMinute * 60 * 1000;

        // 현재 날짜를 시작 날짜로 설정합니다.
        let currentDate = new Date(startDate);

        let data = [];
        let num = 1;

        setLoading(true);
        setTemporary([]);

        setTimeout(() => {
            // 종료 날짜를 포함하여 반복문을 돌립니다.
            while (currentDate <= endDate) {
                const currentYmd = moment(currentDate).format('YYYY-MM-DD');

                const startDateTime = new Date(currentYmd + ' ' + startTime);
                const endDateTime = new Date(currentYmd + ' ' + endTime);

                // const startGetTime = startDate.getTime();
                // const endGetTime = endDate.getTime();
                // const diffTime = endGetTime - startGetTime;

                const cancelDate = new Date(startDateTime);

                cancelDate.setDate(startDateTime.getDate() - cancelDay);
                cancelDate.setHours(cancelHour);
                cancelDate.setMinutes(0);

                const generate = generateData(
                    num,
                    startDateTime,
                    endDateTime,
                    intervalTime,
                    cancelDate
                );

                num += generate.length;

                // console.log('generate: ', generate);

                data.push(...generate);

                // 현재 날짜를 하루씩 증가시킵니다.
                currentDate.setDate(currentDate.getDate() + 1);
            }

            setTemporary(data);
        }, 1000);
    };

    const benefitFunc = (temp) => {
        const arr = Array.from(temp.benefit);
        let data = arr.map((v, index) => (
            <Chip
                key={index}
                label={v}
                size="small"
                sx={{ m: 0.3 }}
                onDelete={() => tempUpdate(temp.num, 'benefit', v)}
            />
        ));

        return data;
    };

    const confirmed = (state) => {
        if (state === 'insert') {
            setModalTitle('부킹 등록');
            setModalOnSuccess(() => () => bookingInsert());
        } else if (state === 'delete') {
            setModalTitle('선택 삭제');
            setModalOnSuccess(() => () => deleteCheckedItems());
        }

        setModalOpen(true);
    };

    const bookingInsert = () => {
        const parsedData = temporary.map((item) => ({
            ...item,
            greenfee: parseNumber(item.greenfee),
            greenfeeDiscount: parseNumber(item.greenfeeDiscount),
            cartfee: parseNumber(item.cartfee),
            caddiefee: parseNumber(item.caddiefee),
        }));

        const params = {
            token: localStorage.getItem('auth-token'),
            data: parsedData,
        };

        postData(
            API.DISCOUNT_INSERT,
            params,
            () => {
                setModalOpen(false);
                alert('부킹할인이 등록되었습니다.');
                navigate('/dc/bookingdiscount');
            },
            (error) => {
                setErrorAlert(true);
                setErrorMessage(error);
            }
        );

        // axios
        //     .post(`${apiUrl}/club/admin/discountInsert`, params)
        //     .then((res) => {
        //         console.log(res);
        //         setModalOpen(false);
        //         alert('부킹할인이 등록되었습니다.');
        //         navigate('/bookingdiscount');
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         setErrorAlert(true);
        //         setErrorMessage(error);
        //     });
    };

    useEffect(() => {
        if (temporary.length > 0) {
            requestAnimationFrame(() => {
                setLoading(false);
            });
        }
    }, [temporary]);

    return (
        <Container maxWidth="true">
            <Typography variant="h4" sx={{ mt: 5, mb: 5 }}>
                할인부킹 등록
            </Typography>
            {/* <Card> */}
            <TableContainer
                sx={{ overflow: 'unset' }}
                className="create-container"
            >
                <Table sx={{ minWidth: 800 }}>
                    <TableBody>
                        <TableRow>
                            <TableCell component={'th'}>시작코스</TableCell>
                            <TableCell sx={{ pt: 0, pb: 0 }}>
                                {/* {club.courses
                                        .split(',')
                                        .map((course, index) => (
                                            <MenuItem
                                                key={index}
                                                value={course}
                                            >
                                                {course}
                                            </MenuItem>
                                        ))} */}
                                <Select
                                    labelId="courses-label"
                                    id="courses"
                                    value={courses}
                                    size="small"
                                    displayEmpty
                                    onChange={coursesChange}
                                >
                                    <MenuItem value="">코스 선택</MenuItem>
                                    {club.length > 0 &&
                                        club[0].courses
                                            .split(',')
                                            .map((course, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={course}
                                                >
                                                    {course}
                                                </MenuItem>
                                            ))}
                                </Select>
                            </TableCell>
                            <TableCell component={'th'}>홀수</TableCell>
                            <TableCell sx={{ pt: 0, pb: 0 }}>
                                <Select
                                    labelId="holes-label"
                                    id="holes"
                                    value={holes}
                                    size="small"
                                    displayEmpty
                                    onChange={holesChange}
                                >
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={18}>18</MenuItem>
                                    <MenuItem value={27}>27</MenuItem>
                                    <MenuItem value={36}>36</MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell component={'th'}>최소인원</TableCell>
                            <TableCell sx={{ pt: 0, pb: 0 }}>
                                <Select
                                    labelId="people-label"
                                    id="people"
                                    value={people}
                                    size="small"
                                    displayEmpty
                                    onChange={peopleChange}
                                >
                                    <MenuItem value={2}>2명</MenuItem>
                                    <MenuItem value={3}>3명</MenuItem>
                                    <MenuItem value={4}>4명</MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell component={'th'}>취소시한</TableCell>
                            <TableCell sx={{ pt: 1, pb: 0 }}>
                                <TextField
                                    type="number"
                                    id="cancelDay"
                                    value={cancelDay}
                                    helperText="일전"
                                    size="small"
                                    sx={{ maxWidth: 60 }}
                                    onChange={cancelDayChange}
                                    disabled={cancelNotPossible ? true : false}
                                />
                                <TextField
                                    type="number"
                                    id="cancelHour"
                                    value={cancelHour}
                                    helperText="시 까지"
                                    size="small"
                                    sx={{ maxWidth: 70, ml: 1 }}
                                    onChange={cancelHourChange}
                                    disabled={cancelNotPossible ? true : false}
                                />
                                <FormControlLabel
                                    value="취소불가"
                                    control={
                                        <Checkbox
                                            value={1}
                                            checked={cancelNotPossibleChecked}
                                            onChange={handleCancelNotPossible}
                                        />
                                    }
                                    label="취소불가"
                                    labelPlacement="end"
                                    sx={{ ml: 1 }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component={'th'}>날짜</TableCell>
                            <TableCell colSpan={3}>
                                <TextField
                                    type="date"
                                    id="bookingDate"
                                    value={bookingDate}
                                    size="small"
                                    sx={{ minWidth: 120 }}
                                    onChange={bookingDateChange}
                                />
                                <Box
                                    component="span"
                                    sx={{
                                        display: 'inline-block',
                                        margin: 1,
                                    }}
                                >
                                    ~
                                </Box>
                                <TextField
                                    type="date"
                                    id="bookingDate"
                                    value={bookingEndDate}
                                    size="small"
                                    sx={{ minWidth: 120 }}
                                    onChange={bookingEndDateChange}
                                />
                            </TableCell>
                            <TableCell component={'th'}>간격</TableCell>
                            <TableCell>
                                <Select
                                    labelId="interval-label"
                                    id="interval"
                                    value={intervalMinute}
                                    size="small"
                                    displayEmpty
                                    onChange={intervalMinuteChange}
                                >
                                    <MenuItem value={6}>6분</MenuItem>
                                    <MenuItem value={7}>7분</MenuItem>
                                    <MenuItem value={8}>8분</MenuItem>
                                    <MenuItem value={9}>9분</MenuItem>
                                    <MenuItem value={10}>10분</MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell component={'th'}>시간</TableCell>
                            <TableCell>
                                <TextField
                                    type="time"
                                    id="startTime"
                                    size="small"
                                    value={startTime}
                                    sx={{ width: 140 }}
                                    onChange={startTimeChange}
                                />
                                {/* <FormControl sx={{ pt: 1 }}>~</FormControl> */}
                                <span
                                    style={{
                                        display: 'inline-block',
                                        margin: 8,
                                    }}
                                >
                                    ~
                                </span>
                                <TextField
                                    type="time"
                                    id="endTime"
                                    size="small"
                                    value={endTime}
                                    sx={{ width: 140 }}
                                    onChange={endTimeChange}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component={'th'}>그린피(정가)</TableCell>
                            <TableCell>
                                <TextField
                                    id="greenfee"
                                    value={greenfee}
                                    size="small"
                                    sx={{ maxWidth: 120 }}
                                    onChange={greenfeeChange}
                                />
                            </TableCell>
                            <TableCell component={'th'}>할인 그린피</TableCell>
                            <TableCell>
                                <TextField
                                    id="greenfeeDiscount"
                                    value={greenfeeDiscount}
                                    size="small"
                                    sx={{ maxWidth: 120 }}
                                    onChange={greenfeeDiscountChange}
                                />
                            </TableCell>
                            <TableCell component={'th'}>카트피</TableCell>
                            <TableCell>
                                <TextField
                                    id="cartfee"
                                    value={cartfee}
                                    size="small"
                                    sx={{ maxWidth: 120 }}
                                    onChange={cartfeeChange}
                                />
                            </TableCell>
                            <TableCell component={'th'}>캐디피</TableCell>
                            <TableCell>
                                <TextField
                                    id="caddiefee"
                                    value={caddiefee}
                                    size="small"
                                    sx={{ maxWidth: 120 }}
                                    onChange={caddiefeeChange}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component={'th'}>제공혜택</TableCell>
                            <TableCell colSpan={7} sx={{ pt: 1, pb: 1 }}>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        {benefitItems.map((benefit, index) => (
                                            <FormControlLabel
                                                key={index}
                                                value={benefit}
                                                control={
                                                    <BenefitCheckBox
                                                        id={`id` + index}
                                                        text={benefit}
                                                        checkItemHandler={
                                                            checkItemHandler
                                                        }
                                                    />
                                                    // <Checkbox
                                                    //     inputProps={{
                                                    //         id:
                                                    //             `id` +
                                                    //             index,
                                                    //         checked:
                                                    //             benefitChecked,
                                                    //     }}
                                                    // />
                                                }
                                                label={benefit}
                                                labelPlacement="end"
                                                // checked={benefitChecked}
                                                // onChange={benefitChange}
                                            />
                                        ))}
                                        <FormControlLabel
                                            value="직접입력"
                                            control={
                                                <Checkbox
                                                    value={'직접입력'}
                                                    checked={benefitEtcChecked}
                                                    onChange={
                                                        handleBenefitEtcChecked
                                                    }
                                                />
                                            }
                                            label="직접입력"
                                            labelPlacement="end"
                                        />

                                        <TextField
                                            id="benefitEtc"
                                            size="small"
                                            value={benefitEtc}
                                            onChange={benefitEtcChange}
                                            // onChange={(e) =>
                                            //     setBenefitEtc(
                                            //         e.target.value
                                            //     )
                                            // }
                                        />
                                    </FormGroup>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component={'th'}>회원가입</TableCell>
                            <TableCell colSpan={7} sx={{ pt: 1, pb: 1 }}>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            control={
                                                <BenefitCheckBox
                                                    id={`idjoin`}
                                                    text="전원 골프장 사이트 회원가입 필수"
                                                    checkItemHandler={
                                                        checkItemHandler
                                                    }
                                                />
                                            }
                                            label="전원 골프장 사이트 회원가입 필수"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {/* </Card> */}
            <Box>
                <Collapse in={errorAlert}>
                    <Alert variant="filled" severity="error" sx={{ m: 2 }}>
                        {errorMessage}
                    </Alert>
                </Collapse>
            </Box>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={5}
                mt={2}
            >
                <Button
                    variant="contained"
                    color="error"
                    disabled={
                        temporary.length > 0 && selected.length > 0
                            ? false
                            : true
                    }
                    onClick={() => confirmed('delete')}
                >
                    {selected.length > 0 && selected.length} 선택 삭제
                </Button>
                <Button
                    variant="contained"
                    // color="inherit"
                    onClick={temporaryCreate}
                >
                    할인부킹 생성
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    disabled={temporary.length > 0 ? false : true}
                    onClick={() => confirmed('insert')}
                >
                    부킹 등록
                </Button>
            </Stack>

            {/* <Card> */}
            <TableContainer>
                <Table sx={{ minWidth: 800 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={
                                        selected.length > 0 &&
                                        selected.length < temporary.length
                                    }
                                    checked={
                                        temporary.length > 0 &&
                                        selected.length === temporary.length
                                    }
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            <TableCell>시작코스명</TableCell>
                            <TableCell align="center">홀수</TableCell>
                            <TableCell align="center">최소인원</TableCell>
                            <TableCell align="center">부킹일자</TableCell>
                            <TableCell align="center">부킹시간</TableCell>
                            <TableCell align="center">취소시한</TableCell>
                            <TableCell align="center">그린피</TableCell>
                            <TableCell align="center">할인 그린피</TableCell>
                            <TableCell align="center">카트피</TableCell>
                            <TableCell align="center">캐디피</TableCell>
                            <TableCell>제공혜택</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {loading && (
                            <TableRow>
                                <TableCell
                                    colSpan={12}
                                    align="center"
                                    sx={{ p: 4 }}
                                >
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}

                        {temporary.map((temp, index) => (
                            <TableRow key={index} role="checkbox">
                                <TableCell
                                    padding="checkbox"
                                    selected={selected.indexOf(temp.num) !== -1}
                                >
                                    <Checkbox
                                        disableRipple
                                        checked={
                                            selected.indexOf(temp.num) !== -1
                                        }
                                        onChange={(event) =>
                                            handleClick(event, temp.num)
                                        }
                                    />
                                </TableCell>
                                <TableCell>{temp.courses}</TableCell>
                                <TableCell align="center">
                                    <Select
                                        labelId={`holes-label-${index}`}
                                        id={`holes-${index}`}
                                        value={temp.holes}
                                        size="small"
                                        displayEmpty
                                        onChange={(e) =>
                                            tempUpdate(
                                                temp.num,
                                                'holes',
                                                e.target.value
                                            )
                                        }
                                    >
                                        <MenuItem value={9}>9</MenuItem>
                                        <MenuItem value={18}>18</MenuItem>
                                        <MenuItem value={27}>27</MenuItem>
                                        <MenuItem value={36}>36</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell align="center">
                                    {/* {temp.people} */}
                                    <Select
                                        labelId={`people-label-${index}`}
                                        id={`people-${index}`}
                                        value={temp.people}
                                        size="small"
                                        displayEmpty
                                        onChange={(e) =>
                                            tempUpdate(
                                                temp.num,
                                                'people',
                                                e.target.value
                                            )
                                        }
                                    >
                                        <MenuItem value={2}>2명</MenuItem>
                                        <MenuItem value={3}>3명</MenuItem>
                                        <MenuItem value={4}>4명</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell align="center">
                                    {temp.bookingDate}
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        type="time"
                                        value={temp.bookingHour}
                                        size="small"
                                        onChange={(e) =>
                                            tempUpdate(
                                                temp.num,
                                                'bookingHour',
                                                e.target.value
                                            )
                                        }
                                        // sx={{
                                        //     maxWidth: 70,
                                        // }}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    {temp.cancelNotPossible
                                        ? '취소불가'
                                        : temp.cancelDay}
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        value={temp.greenfee}
                                        size="small"
                                        onChange={(e) =>
                                            tempUpdate(
                                                temp.num,
                                                'greenfee',
                                                e.target.value
                                            )
                                        }
                                        sx={{
                                            maxWidth: 120,
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        value={temp.greenfeeDiscount}
                                        size="small"
                                        onChange={(e) =>
                                            tempUpdate(
                                                temp.num,
                                                'greenfeeDiscount',
                                                e.target.value
                                            )
                                        }
                                        sx={{
                                            maxWidth: 120,
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        value={temp.cartfee}
                                        size="small"
                                        onChange={(e) =>
                                            tempUpdate(
                                                temp.num,
                                                'cartfee',
                                                e.target.value
                                            )
                                        }
                                        sx={{
                                            maxWidth: 120,
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        value={temp.caddiefee}
                                        size="small"
                                        onChange={(e) =>
                                            tempUpdate(
                                                temp.num,
                                                'caddiefee',
                                                e.target.value
                                            )
                                        }
                                        sx={{
                                            maxWidth: 120,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={{ maxWidth: 200 }}>
                                    {benefitFunc(temp)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* </Card> */}
            <ModalConfirm
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                title={modalTitle}
                content={modalTitle + ' 하시겠습니까?'}
                closeText={'닫기'}
                successText={'확인'}
                onCloseClick={() => setModalOpen(false)}
                onSuccessClick={modalOnSuccess}
            />
        </Container>
    );
}
