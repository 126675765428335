import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Container,
    Divider,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Typography,
} from '@mui/material';

import GolfTableToolbar from './golf-table-toobar';
import Scrollbar from '../../../component/scrollbar';
import GolfTableHead from './golf-table-head';
import { emptyRows, applyFilter, getComparator } from './utils';
import axios from 'axios';
import TableEmptyRows from './table-empty-rows';
import TableNoData from './table-no-data';
import GolfTableRow from './golf-table-row';
import Iconify from '../../../component/iconify';
import { useNavigate } from 'react-router-dom';
import TableCard from 'component/TableCard';

export default function GolfInformationView() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('id');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [users, setUsers] = useState([]);

    const [me, setMe] = useState('');

    useEffect(() => {
        if (!localStorage.getItem('auth-token')) {
            navigate('/login');
        }
    });

    useEffect(() => {
        setMe(JSON.parse(localStorage.getItem('me')));
    }, []);

    useEffect(() => {
        console.log('useEffect');
        if (me) {
            getClubs(me).then((result) => {
                console.log(result);
                if (result.success) {
                    setUsers(result.data);
                } else {
                    alert(result.message);

                    if (result.code === 1000) {
                        localStorage.clear();
                        navigate('/login');
                    }
                }
            });
        }
    }, [me, navigate]);

    const getClubs = async (me) => {
        const params = {
            token: localStorage.getItem('auth-token'),
            user: me,
        };
        const lists = await axios.get(`${apiUrl}/club/admin/lists`, { params });
        console.log(lists);

        // const result = lists.data;
        return lists.data;
        // if (result.success) {
        // 	setUsers(result.data);
        // } else {
        // 	alert(result.message);

        // 	if (result.code === 1000) {
        // 		localStorage.clear();
        // 		navigate('/login');
        // 	}
        // }
    };

    const handleSort = (event, id) => {
        const isAsc = orderBy === id && order === 'asc';
        if (id !== '') {
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(id);
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const dataFiltered = applyFilter({
        inputData: users,
        comparator: getComparator(order, orderBy),
        filterName,
    });

    const notFound = !dataFiltered.length && !!filterName;

    return (
        <Container maxWidth="true">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h4">골프장 관리</Typography>
                {me.level === 10 && (
                    <Button variant="contained" color="inherit">
                        골프장 등록
                    </Button>
                )}
            </Stack>
            <Divider />

            {/* <Card> */}
            <GolfTableToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
            />
            <TableCard>
                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }}>
                        <Table sx={{ minWidth: 800 }}>
                            <GolfTableHead
                                order={order}
                                orderBy={orderBy}
                                rowCount={users.length}
                                numSelected={selected.length}
                                onRequestSort={handleSort}
                                onSelectAllClick={handleSelectAllClick}
                                headLabel={[
                                    { id: 'id', label: 'ID' },
                                    { id: 'club_code', label: '클럽코드' },
                                    { id: 'name', label: '골프장' },
                                    { id: 'address', label: '주소' },
                                    // { id: 'webpage', label: '홈페이지' },
                                    { id: 'phone', label: '대표전화' },
                                    { id: 'courses', label: '코스정보' },
                                    { id: 'holes', label: '홀수' },
                                    { id: 'user', label: '담당자' },
                                    { id: 'state', label: '상태' },
                                    { id: 'updated_at', label: '등록일시' },
                                    // { id: '' },
                                ]}
                            />
                            <TableBody>
                                {dataFiltered
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    ?.map((row) => (
                                        <GolfTableRow
                                            key={row.id}
                                            id={row.id}
                                            name={row.name}
                                            address={row.address}
                                            webpage={row.webpage}
                                            phone={row.phone}
                                            courses={row.courses}
                                            holes={row.holes}
                                            updated={row.updated_at}
                                            clubCode={row.club_code}
                                        />
                                    ))}

                                <TableEmptyRows
                                    height={77}
                                    emptyRows={emptyRows(
                                        page,
                                        rowsPerPage,
                                        users.length
                                    )}
                                />

                                {notFound && <TableNoData query={filterName} />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    page={page}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[50, 100, 200]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableCard>

            {/* </Card> */}
        </Container>
    );
}
