import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
    Alert,
    Typography,
    Box,
    Grid,
    Button,
    Collapse,
    Container,
    TextField,
    Stack,
} from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authState } from '../recoil/authState';
import { useTheme } from '@emotion/react';
// import LoginForm from '../component/LoginForm';

export default function Login() {
    const auth = useRecoilValue(authState);
    const setAuth = useSetRecoilState(authState);

    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const [id, setId] = useState('');
    const [password, setPassword] = useState('');

    const [errorAlert, setErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // useEffect(() => {
    //     console.log('useEffect');
    //     if (localStorage.getItem('auth-token')) {
    //         navigate('/');
    //     }
    // });
    const handleLogin = () => {
        // console.log(`Username: ${id}, Password: ${password}`);

        axios
            .post(`${apiUrl}/authClubAdmin`, {
                // email: id,
                id,
                password,
            })
            .then((response) => {
                console.log(response);
                const data = response.data;
                console.log(data);
                if (data.success) {
                    localStorage.setItem('auth-token', data.token);
                    localStorage.setItem('me', JSON.stringify(data.user));

                    setAuth({
                        isLoggedIn: true,
                        user: data.user,
                        token: data.token,
                    });

                    navigate('/');
                } else {
                    localStorage.clear();
                    setErrorMessage(data.message);
                    setErrorAlert(true);
                    //   setMessage('Invalid username or password');
                }
            })
            .catch((error) => {
                // setMessage('Error occurred while logging in');
            });
    };
    const theme = useTheme();

    const [passwordHasValue, setPasswordHasValue] = useState(false);
    const [idHasValue, setIdHasValue] = useState(false);

    const makeAnimationStartHandler = (stateSetter) => (e) => {
        const autofilled = !!e.target?.matches('*:-webkit-autofill');
        if (e.animationName === 'mui-auto-fill') {
            stateSetter(autofilled);
        }

        if (e.animationName === 'mui-auto-fill-cancel') {
            stateSetter(autofilled);
        }
    };

    return auth.isLoggedIn ? (
        <Navigate to={'/'} />
    ) : (
        <>
            <Stack
                sx={{ width: '100%', height: '100%' }}
                display="flex"
                justifyContent="center"
                alignItems={'center'}
            >
                <Grid
                    container
                    width={'1200px'}
                    height={'800px'}
                    boxShadow={5}
                    sx={{ backgroundColor: theme.palette.primary.dark }}
                >
                    <Grid
                        item
                        xs={6}
                        sx={{
                            backgroundImage: `url(/login-bg.png)`,
                            // backgroundPosition: "center"

                            backgroundSize: 'cover',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '40px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    color: 'white',
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    marginTop: '100px',
                                }}
                            >
                                골프로 연결되는 골프친구, 티샷
                            </Typography>
                            <div>
                                <Box
                                    sx={{
                                        margin: '0 auto',
                                        width: `${(189 / (189 + 48)) * 500}px`,
                                        height: `${(48 / (189 + 48)) * 500}px`,
                                        backgroundPosition: 'center',

                                        // back
                                        backgroundSize: 'cover',

                                        // backgrond ,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundImage:
                                            'url(/teeshot-logo.svg)',
                                    }}
                                >
                                    {' '}
                                </Box>
                            </div>

                            <div>
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        gap: 1,
                                        flexDirection: 'row',
                                        marginTop: '30px',
                                    }}
                                >
                                    <a
                                        href="https://play.google.com/store/apps/details?id=kr.co.teeshot.app"
                                        target="_blank"
                                    >
                                        <Button
                                            variant="contained"
                                            color="inherit"
                                        >
                                            <img src="google.svg" />{' '}
                                            <Typography
                                                sx={{
                                                    marginLeft: '10px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Google Play
                                            </Typography>
                                        </Button>
                                    </a>
                                    <a
                                        href="https://apps.apple.com/kr/app/id1092007968"
                                        target="_blank"
                                    >
                                        <Button
                                            variant="contained"
                                            color="inherit"
                                        >
                                            <img src="apple.svg" />{' '}
                                            <Typography
                                                sx={{
                                                    marginLeft: '10px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                App Store
                                            </Typography>
                                        </Button>
                                    </a>
                                </Stack>
                            </div>
                        </Stack>
                        <Stack
                            sx={{
                                padding: '30px',
                                fontSize: '12px',
                                // color: 'common.offWhite',
                                color: 'grey.400',
                                '.gray': {
                                    color: 'lightGray',
                                    marginTop: '10px',
                                },
                            }}
                        >
                            <div> (주)에이치비엠피 대표이사 정봉훈</div>
                            <div>
                                서울특별시 구로구 디지털로 26길 111,
                                JNK디지털타워 612호 Tel : 02-2277-3489
                            </div>
                            <div> 사업자등록번호 : 119-86-94960</div>
                            <div>
                                통신판매업 신고번호 : 2020-제주이도2-0040호
                            </div>
                            <div>
                                이용약관개인정보처리방침위치기반서비스이용약관
                            </div>
                            <div className="gray">
                                Copyright © (주)에이치비엠피 All Rights
                                Reserved.
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Container
                            // maxWidth="xs"
                            sx={{
                                backgroundColor: 'white',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleLogin();
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        // height: '100%',
                                    }}
                                >
                                    <h1>
                                        <center>단체부킹 관리 로그인</center>
                                    </h1>
                                    <TextField
                                        // ref={idRef}
                                        margin="normal"
                                        required
                                        fullWidth
                                        inputProps={{
                                            onAnimationStart:
                                                makeAnimationStartHandler(
                                                    setIdHasValue
                                                ),
                                        }}
                                        InputLabelProps={{
                                            shrink: idHasValue || id,
                                        }}
                                        label="id"
                                        value={id}
                                        onChange={(e) => {
                                            setId(e.target.value);
                                            setErrorAlert(false);
                                        }}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="Password"
                                        inputProps={{
                                            onAnimationStart:
                                                makeAnimationStartHandler(
                                                    setPasswordHasValue
                                                ),
                                        }}
                                        InputLabelProps={{
                                            shrink: passwordHasValue || password,
                                        }}
                                        type="password"
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            setErrorAlert(false);
                                        }}
                                    />
                                    <Box>
                                        <Collapse in={errorAlert}>
                                            <Alert
                                                variant="filled"
                                                severity="error"
                                                sx={{ m: 2 }}
                                            >
                                                {errorMessage}
                                            </Alert>
                                        </Collapse>
                                    </Box>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        size="large"
                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        로그인
                                    </Button>
                                </Box>
                            </form>
                        </Container>
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
}
