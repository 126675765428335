import { useState } from 'react';
import {
    Breadcrumbs,
    AppBar,
    Box,
    IconButton,
    MenuItem,
    Popover,
    Typography,
    Stack,
    Link,
    Toolbar,
    Button,
} from '@mui/material';
import { DensityMedium, NavigateNext } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { useResponsive } from '../hooks/use-responsive';

import { bgBlur } from '../theme/css';

import { NAV, HEADER } from './config-layout';
import {
    useLocation,
    useMatch,
    useNavigate,
    useParams,
    useRoutes,
} from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { authState } from '../recoil/authState';
import { usePathname, useRouter } from 'routes/hooks';
import { navData as topNavData, useBottomNavData } from './navData';

const Breadcrumb = (props) => {
    function findBreadcrumbPath(navData, pathSegments) {
        let path = [];
        let currentLevel = navData;
        for (const segment of pathSegments) {
            let match;
            if (currentLevel?.find) {
                match = currentLevel.find((item) => item.key === segment);
            }
            if (match) {
                path.push(match);
                currentLevel = match.child;
            } else {
                break;
            }
        }

        return path;
    }

    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean); // Split path and remove empty segments
    const nP = pathSegments.map((p) => {
        if (Number.isNaN(Number(p))) {
            return p;
        }
        return ':id';
    });
    const theme = useTheme();
    // Find the breadcrumb path from navData
    const breadcrumbPath = findBreadcrumbPath(props.navData, nP);
    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
                color: theme.palette.common.darkGrey,
                // color: "darkGrey",
                '.MuiBreadcrumbs-separator': {
                    margin: '0 4px !important', // Adjust spacing around the separator
                },
            }}
            separator={<NavigateNext fontSize="14px" sx={{ mt: '3px' }} />}
        >
            {breadcrumbPath.map((item, index) => {
                const isLast = index === breadcrumbPath.length - 1;
                const to = '/' + pathSegments.slice(0, index + 1).join('/');
                return (
                    <Link
                        sx={{ fontSize: '14px !important', fontWeight: 'bold' }}
                        key={item.key}
                        // component={RouterLink}
                        to={to}
                        underline={'none'}
                        color="inherit"
                    >
                        {item.name}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default function Header() {
    // const nav = useNavigate();
    // console.log('nav :', nav);
    const bottomNavData = useBottomNavData();
    const setAuth = useSetRecoilState(authState);
    const auth = useRecoilValue(authState);

    const [open, setOpen] = useState(null);
    const navigate = useNavigate();

    const theme = useTheme();
    const lgUp = useResponsive('up', 'lg');

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };
    const handleClose = () => {
        setOpen(null);
    };
    const logoutHandle = () => {
        setAuth({
            isLoggedIn: false,
            user: null,
            token: null,
        });
        localStorage.clear();
        navigate('login');
    };

    return (
        <AppBar
            sx={{
                // boxShadow: 2,
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '15px',
                // height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                bgcolor: theme.palette.common.offWhite,
                width: `calc(100% - ${NAV.WIDTH + 1}px)`,
            }}
        >
            <Stack
                display={'flex'}
                flex={1}
                flexDirection={'row'}
                justifyContent={'space-between'}
            >
                <Stack flex={1}>
                    <Breadcrumb navData={[...topNavData, ...bottomNavData]} />
                </Stack>
                <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={1}
                >
                    <Stack
                        sx={{
                            color: theme.palette.common.darkGrey,
                            fontSize: '14px',
                            fontWeight: 'bold',
                            lineHeight: 1,
                        }}
                    >
                        {auth.user.club_admin_id}
                    </Stack>
                    <Stack
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                    >
                        <Button
                            onClick={handleOpen}
                            size="small"
                            sx={{
                                mt: '2px',
                                minWidth: '0px !important',
                                width: '30px !important',
                            }}
                        >
                            <DensityMedium
                                fontSize="2px"
                                sx={{ color: theme.palette.common.darkGrey }}
                            />
                        </Button>

                        <Popover
                            open={!!open}
                            anchorEl={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem
                                disableRipple
                                disableTouchRipple
                                onClick={logoutHandle}
                                sx={{
                                    typography: 'body2',
                                    color: 'error.main',
                                    py: 1.5,
                                }}
                            >
                                로그아웃
                            </MenuItem>
                        </Popover>
                    </Stack>
                </Stack>
            </Stack>
        </AppBar>
    );
}
